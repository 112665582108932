import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  message;
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {

    if (this.authService.isLoggedIn()) {
      const userActivityConfig = JSON.parse(localStorage.getItem('USER_ACTIVITY_CONFIG'));
      const reloadConfig: any = userActivityConfig.find(res => res.code === 'REFRESH_LOGOUT');
      if (reloadConfig.value.toLowerCase() === 'yes') {
        // this.authService.logout();
        // setTimeout(() => { this.router.navigate(['/']); });

        // console.log('REFRESH');
        // const items = { ...localStorage };
        // if (items.hasOwnProperty("userId")) {
        //   this.router.navigate(['/welcome']);
        // }

      }
    }


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    document.addEventListener("visibilitychange", (res) => {
      const items = { ...localStorage };
      const url = this.router.url;
      if (items.hasOwnProperty("userId")) {
        if (url == '/' || url == '') {
          console.log('this.router.url', url)
          this.router.navigate(['/welcome']);
        }
      } else {
        if (
          url.includes('/registration') ||
          url.includes('/distillery/') ||
          url.includes('/brewery/') ||
          url.includes('/masterwarehouse/') ||
          url.includes('/ndlcpermit/') ||
          url.includes('/micro-brewery/') ||
          url.includes('/eventbarlicense/') ||
          url.includes('/sacramental/') ||
          url.includes('/industrial-alcohol/') ||
          url.includes('/ndo2/') ||
          url.includes('/custom-bond/') ||
          url.includes('/scramental/') ||
          url.includes('/distillery/') ||
          url.includes('/retailmodel/') ||
          url.includes('/bottling-new-license/') ||
          url.includes('/viewLicenseStatus/')) {
          console.log('test --- ')
        } else {
          this.authService.logout();
          this.router.navigate(['/']);
        }
      }
    });
  }
}
