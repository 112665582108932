import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class TankregistartionServiceBrew {

  baseURL = environment.parentUnit;
  constructor(private http: HttpClient) { }

  getvessaltypes(): Observable<any> {
    const url = this.baseURL + apiUrls.vesseltype;
    return this.http.get(url);

  }
  getTanRegById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration?id=' + id;
    return this.http.get(url, id);
  }
  getTanOverviewRegById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/' + id;
    return this.http.get(url, id);
  }
  getTankLog(entityType,id, tankType): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/getOverviewStockRelatedLog?entityType='+ entityType +'&tankId=' + id + '&tankType=' + tankType;
    return this.http.get(url, id);
  }
  getTankLogSM(entityType,id, tankType): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/getOverviewStockRelatedLog?entityType='+ entityType +'&tankId=' + id + '&tankType=' + tankType;
    return this.http.get(url, id);
  }
  getTankLogPD(entityType,id, tankType,pageNo?,pageSize?): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/getOverviewStockRelatedLog?entityType='+ entityType+'&tankId=' + id + '&tankType=' + tankType +'&page=' +pageNo+ '&size=' +pageSize;
    return this.http.get(url, id);
  }
  addTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration';
    return this.http.post(url, postdata);
  }
  approveTanReg(postdata): Observable<any> {
    // /breweryTankRegistration
    const url = this.baseURL + apiUrls.addTankReg;
    return this.http.post(url, postdata);
  }
  updateTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addTankReg;
    return this.http.post(url, postdata);
  }
  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, postdata);
  }
  searchTanRegOveriew(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/search';
    return this.http.post(url, postdata);
  }

  searchDrumReg(payload):Observable<any>{
    const url = this.baseURL +'breweryTankRegistration/drumRegistration/lazySearch';
    return this.http.post(url,payload);
  }


  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber + '&liquorType=' + data.liquorType;
    return this.http.post(url, data).toPromise();
  }

  getlabledata(): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/tankStatusCount';
    return this.http.get(url);
  }

  gettankviewlist(data, listtype): Observable<any> {
    const url = this.baseURL + apiUrls.gettankviewlist + listtype;
    return this.http.post(url, data);
  }
  getTanRegBytankId(id): Observable<any> {
    const url = this.baseURL + 'tankRegistration/getTankRegByTankId?tankId=' + id;
    return this.http.get(url, id);
  }
  gettankIdList(data): Observable<any> {
    const url = this.baseURL + 'findTankRegistration/dropDown';
    return this.http.post(url, data);
  }
  getTankDetailByName(paylod): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/tankQuantity';
    return this.http.post(url, paylod);
  }
  getCount(entityType?): Observable<any> {
    // scmProduction/breweryTankRegistration/overview/getTankCategoryCount
    const url = this.baseURL + 'breweryTankRegistration/overview/getTankCategoryCount?entityType=' + entityType;
    return this.http.get(url);
  }
  getDesignatedMaterialBytankId(data): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion/getDesignatedMaterial?tankId=' + data.tankid + '&conversionType=' + data.conversionType;
    return this.http.get(url, data);
  }
  getDesignatedMaterialBytankIdd(data): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion/getDesignatedMaterial?tankId=' + data + '&conversionType=' + 'TANK_CONVERSION';
    return this.http.get(url, data);
  }

  search(payload): Observable<any> {
    const url = this.baseURL + "bottlingProductionPlan/search";
    return this.http.post(url, payload);
  }
  search1(payload): any {
    const url = this.baseURL + "bottlingProductionPlan/search";
    return this.http.post(url, payload).toPromise();
  }
  getStatusCount(entityType?, isDrumRegTanktype?): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/getStatusCount?entityType='+entityType+'&isDrumRegTanktype='+isDrumRegTanktype;
    return this.http.post(url,entityType,isDrumRegTanktype);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }

  getTankDetailsPreDeclaration(payload){
    const url = this.baseURL + 'distillery/bottlingPlan/batchDetails'
    return this.http.post(url,payload);
  }

  getTankDetailsPreDeclaration1(payload){
    const url = this.baseURL + 'bottlingPlan/declaraionDetails'
    return this.http.post(url,payload);
  }

  getTankDetailLog(payload){
    const url = this.baseURL + 'tankLogs/getTankDetailLog'
    return this.http.post(url, payload)
  }
}
