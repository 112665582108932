<h5 class="m-b-10 ng-star-inserted">{{'master.common.view' | translate}} Accidental Request for OMD
</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"
            routerLinkActive="router-link-active"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'">
        <a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item" routerLink="/{{url}}/omd/accidentalomd/list"><a href="javascript:">Accidental Request for OMD</a>
    </li>
    <li class="breadcrumb-item">{{'master.common.view' | translate}} </li>
</ul>
<div class="row">
    <div class="col-12">
        <app-card cardTitle="Accidental Request Details" class="list-panel add-panel">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Request Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.requestDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">PD25 OMD Transport Pass No<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.omdPassNo}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Transport Pass Issue Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.tpIssueDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Valid Upto<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <span>{{editObject?.validUpTo | date:'dd/MM/yyyy hh:mm:a'}}</span>
                </div>
            </div>
        </app-card>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">License Type<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consignorLicenseType}}
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">License Number<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consignorLicenseNo}}
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">Distillery Name<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.unitName}}
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">Distillery Address<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consignorUnitAddress}}
                        </div>
                    </div>
                </app-card>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <app-card cardTitle="{{'blendTransferReq.add.consigneeDetails' | translate}}"
                    class="list-panel add-panel">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">OMD Name
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consigneeUnitName}}
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">OMD Registeration / {{'molassesDC.list.licenseNumber' |
                                translate}}<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consigneeLicenseNumber}}
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label class="w-100 view-label">OMD Address<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            {{editObject?.consigneeUnitAddress}}
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
    <div class="col-xl-12">
        <app-card cardTitle="Indent Details" class="list-panel add-panel">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="w-100 view-label">Indent No<span class="float-right">:</span></label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    {{editObject?.indentNo}}
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="w-100 view-label">
                        Indent Date
                        <span class="float-right">:</span></label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    {{editObject?.indentDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="w-100 view-label">
                        Denatured Fees (in Rs.)
                        <span class="float-right">:</span></label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    {{editObject?.denaturationFees}}
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="w-100 view-label">
                        Dispatch (License) Fee (in Rs.)
                        <span class="float-right">:</span></label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    {{editObject?.dispatchLicenseFees }}
                </div>
            </div>
        </app-card>
        <app-card cardTitle="Accidental Details" class="list-panel view-panel">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accidental Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.accidentalDate | date:'dd/MM/yyyy'}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accidental Domain<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.accidentalDomainValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accidental Location (District)<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.accidentalDistrictValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label"> Accidental Location Address<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.accidentalAddress}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Remarks (Entered By User)<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.userRemarks}}
                </div>

            </div>
        </app-card>


    </div>
    <div class="col-xl-12" *ngIf="designation == 'DEO'">
        <form [formGroup]="brandForm" utocomplete="off">
            <app-card cardTitle="Additional Document Details" class="list-panel add-panel">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Upload Accidental Report<span
                                class="float-right">:</span></label>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <div class="input-group cust-file-button">
                            <div class="custom-file">
                                <input type="file" id="customFilessss" class="custom-file-input"
                                    formControlName='accidentalReport' accept="image/png,image/jpeg"
                                    (change)="onFileChange($event, 'accidentalReportFiles', brandForm, 'accidentalReport')">
                                <label class="custom-file-label"></label>
                                <label class="custom-file-label" for="customFilessss"></label>
                            </div>
                        </div>

                        <app-show-errors eleName="Upload Accidental Report" [frmSub]="formSubmitted"
                            [control]="getFormCntr('accidentalReport')">
                        </app-show-errors>

                        <div class="error-msg" *ngIf="getFormCntr('accidentalReport').errors?.requiredFileType">
                            <span>
                                <i class="fa fa-times"></i> File type must be png/jpeg/jpg/pdf
                            </span>
                        </div>
                        <div class="error-msg" *ngIf="getFormCntr('accidentalReport').errors?.fileSizeValidator">
                            <span>
                                <i class="fa fa-times"></i> Please upload less than 6 Mb size
                            </span>
                        </div>
                        <div *ngIf="editObject?.accidentalRequestOmdDocumentList.length>0 && accidentalDocumentName[0].name == 'Upload Accidental Report' ">
                            {{accidentalDocumentName[0].documentName}}
                            <button class="btn btn-primary"  
                                (click)="downloadDocument(accidentalDocumentName[0].uuid,accidentalDocumentName[0].documentName)">
                                <i class="feather icon-download"></i>
                            </button>
                            <span class="download-text ml-1"><button class="btn btn-icon btn-primary "
                                type="button"
                                (click)="DownloadDoc(accidentalDocumentName[0]?.uuid,accidentalDocumentName[0]?.documentName)">
                                <i class="feather icon-eye"></i></button></span>
                        </div>  
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Upload FIR Copy<span class="float-right">:</span></label>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <div class="input-group cust-file-button">
                            <div class="custom-file">
                                <input type="file" id="customFilessss" class="custom-file-input"
                                    formControlName='firCopy' accept="image/png,image/jpeg"
                                    (change)="onFileChange($event, 'firCopyFiles', brandForm, 'firCopy')">
                                <label class="custom-file-label"></label>
                                <label class="custom-file-label" for="customFilessss"></label>
                            </div>
                        </div>

                        <app-show-errors eleName="Upload FIR Copy" [frmSub]="formSubmitted"
                            [control]="getFormCntr('firCopy')">
                        </app-show-errors>

                        <div class="error-msg" *ngIf="getFormCntr('firCopy').errors?.requiredFileType">
                            <span>
                                <i class="fa fa-times"></i> File type must be png/jpeg/jpg/pdf
                            </span>
                        </div>
                        <div class="error-msg" *ngIf="getFormCntr('firCopy').errors?.fileSizeValidator">
                            <span>
                                <i class="fa fa-times"></i> Please upload less than 6 Mb size
                            </span>
                        </div>
                        <div *ngIf="editObject?.accidentalRequestOmdDocumentList.length>0 && firCopy[0]?.name == 'Upload FIR Copy' ">
                            {{firCopy[0].documentName}}
                            <button class="btn btn-primary"  
                                (click)="downloadDocument(firCopy[0].uuid,firCopy[0].documentName)">
                                <i class="feather icon-download"></i>
                            </button>
                            <span class="download-text ml-1"><button class="btn btn-icon btn-primary "
                                type="button"
                                (click)="DownloadDoc(firCopy[0]?.uuid,firCopy[0]?.documentName)">
                                <i class="feather icon-eye"></i></button></span>
                        </div> 
                    </div>
                    </div>
                    <div class="row">
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Wastage<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <input type="text" class="form-control" formControlName="wastage"
                            placeholder="Enter Wastage" maxlength="9" appTwoDigitDecimaNumber>
                    </div> -->
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Accidental Remarks<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <input type="text" class="form-control" formControlName="deoRemarks"
                            placeholder="Enter Remarks" maxlength="500">
                    </div>
                </div>
            </app-card>
        </form>
    </div>
    <div class="col-xl-12" *ngIf="designation != 'DEO' && (editObject?.accidentalRequestOmdDocumentList.length>0 || editObject?.deoRemarks)">
        <form [formGroup]="brandForm" utocomplete="off">
            <app-card cardTitle="Additional Document Details" class="list-panel add-panel">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Upload Accidental Report<span
                                class="float-right">:</span></label>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                            <div *ngIf="editObject?.accidentalRequestOmdDocumentList.length>0">
                                <ng-container *ngIf=" accidentalDocumentName.length >0">
                            {{accidentalDocumentName[0].documentName}}
                            <button class="btn btn-primary"  
                                (click)="downloadDocument(accidentalDocumentName[0].uuid,accidentalDocumentName[0].documentName)">
                                <i class="feather icon-download"></i>
                            </button>
                            <span class="download-text ml-1"><button class="btn btn-icon btn-primary "
                                type="button"
                                (click)="DownloadDoc(accidentalDocumentName[0]?.uuid,accidentalDocumentName[0]?.documentName)">
                                <i class="feather icon-eye"></i></button></span>
                        </ng-container>
                        </div>  
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" >
                        <label class="w-100 view-label">Upload FIR Copy<span class="float-right">:</span></label>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
    
                        <div *ngIf="editObject?.accidentalRequestOmdDocumentList.length>0 && firCopy">
                            <ng-container *ngIf=" firCopy.length >0">
                            {{firCopy[0].documentName}}
                            <button class="btn btn-primary"  
                                (click)="downloadDocument(firCopy[0].uuid,firCopy[0].documentName)">
                                <i class="feather icon-download"></i>
                            </button>
                            <span class="download-text ml-1"><button class="btn btn-icon btn-primary "
                                type="button"
                                (click)="DownloadDoc(firCopy[0]?.uuid,firCopy[0]?.documentName)">
                                <i class="feather icon-eye"></i></button></span>
                        </ng-container>
                        </div> 
                    </div>
                    </div>
                    <div class="row">
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Wastage<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.wastage}}
                    </div> -->
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Accidental Remarks<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.deoRemarks}}
                    </div>
                </div>
            </app-card>
        </form>
    </div>
    <div class="col-xl-12" *ngIf="logactive">
        <app-card cardTitle="Logs" blockClass="table-border-style" class="search-panel">
            <div class="table-responsive">
                <table class="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'brewery.common.date' | translate }}</th>
                            <th>{{'brewery.common.actionperformedby' | translate }}</th>
                            <th>{{'brewery.common.designation' | translate }}</th>
                            <th>{{'brewery.common.action' | translate }}</th>
                            <th>{{'brewery.common.comments' | translate }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let logs of logshow;let i=index'>
                            <td>{{i+1}}</td>
                            <td>{{logs?.actionTime | date:'dd/MM/yyyy HH:mm:ss' :"+0000"}}</td>
                            <td>{{logs?.actionPerformedBy}}</td>
                            <td>{{logs?.designation}}</td>
                            <td>{{logs?.action}}</td>
                            <td>{{logs?.comments}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>
    </div>

    <div class="col-xl-12" *ngIf="logactive">
        <form [formGroup]="brandForm" utocomplete="off">
            <app-card *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable" cardTitle="Remarks"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'brewery.common.remarks' | translate }}
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Remarks"
                                formControlName="remark">
                            <div *ngIf="formSubmitted && (brandform.remark.errors || brandform.remark.dirty || brandform.remark.touched)"
                                class="error-msg">
                                <div *ngIf="brandform.remark?.errors?.required"><i class="fa fa-times"></i>
                                    Remarks is required </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>


            <div class="col-5">
                <div class="d-flex">
                    <div class="flex-fill">
                        <select class="form-control" formControlName="sendBackLevel"
                            *ngIf='editObject?.isShowButtons && workflowdata?.isSendBack'>
                            <option value="">{{'brewery.common.select' | translate }}</option>
                            <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                sebd.value}}
                            </option>
                        </select>
                        <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                            class="error-msg">
                            <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                Dropdown Value Is required </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <button type="button" class="btn btn-warning ml-2 mb-2"
                            (click)="showConfirmation('SENDBACK', brandForm)" [disabled]="isClarificationLoading"
                            *ngIf="(editObject?.isShowButtons) && workflowdata?.isSendBack">
                            <i class="fa"
                                [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                            Request for Clarification
                        </button>
                    </div>
                </div>
            </div>



        </form>
    </div>
    <div class="col-12 text-right mb-4" *ngIf="!logactive">
        <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/accidentalomd/list">{{
            'brewery.common.back' | translate }}</button>
    </div>
    <div class="col-12 text-right mb-4" *ngIf="logactive">
        <button type="submit" class="btn btn-success mb-2 ml-2" (click)="captureScreen()">
            {{ 'language.download' | translate }}</button>
        <!-- <button type="button" *ngIf="!isPreview" class="btn btn-secondary ml-2 mb-2"
            routerLink="/{{url}}/omd/accidentalomd/list">{{'language.back'|translate}}</button> -->
        <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/accidentalomd/list">{{
            'brewery.common.back' | translate }}</button>
        <button type="button" class="btn btn-success mb-2 ml-2" (click)="showConfirmation('APPROVED', brandForm)"
            [disabled]="isApprovedLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
            {{ 'brewery.common.approve' | translate }}</button>
        <button type="button" class="btn btn-danger mb-2 ml-2" (click)="showConfirmation('REJECTED', brandForm)"
            [disabled]="isRejectLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isRejected">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
            {{ 'brewery.common.reject' | translate }}</button>


    </div>

</div>
<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered" (click)="close()">
    <div class="app-modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'APPROVED'">
            Are you sure you want to Approve?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'REJECTED'">
            Are you sure you want to Reject?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'SENDBACK'">
            Are you sure you want to Request for Clarification?
        </h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" (click)="feedback(confirmStyatus)">Yes</button>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" (click)="close()">No</button>
    </div>
</app-ui-modal>

<div class="row ng-star-inserted">
    <div class="col-12">
        <div class="card border shadow-none mb-0 bg-light">
            <div class="card-body p-2">
                <p class="mb-0"><span class="text-primary">*</span>Note:</p>
                <ol class="pl-3 mb-0">
                    <li>The file(s) should be of Size 6 MB</li>
                    <li>The File type applicable are pdf,jpeg,png,jpg,x-png</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<app-ui-modal #exampleModalCenter1 dialogClass="modal-dialog-centered   modal-xl">
    <div class="app-modal-body text-center" style="padding-top: 20px;">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="exampleModalCenter1.hide()"><span aria-hidden="true">&times;</span></button>
        <img *ngIf="!imageboolean" src="data:image/jpeg;base64,{{imageSrcPath}}"
            style="width:100%; object-fit: contain;height:600px" />
        <div *ngIf="imageboolean === true">
            <iframe style="width:100%;height:600px;" target="_blank" frameborder="no"></iframe>
        </div>
        <h3>{{imageDocName}}</h3>
    </div>
</app-ui-modal>
