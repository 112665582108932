import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class BreweryStockAvailabilityService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'breweryStockAvailability/lazySearch';
    return this.http.post(url, payload);
  }
  detail(id: Number): Observable<any> {
    const url = this.baseURL + 'breweryStockAvailability/' + id;
    return this.http.get(url);
  }
  details(lic, raw, id: Number,page ,pageSize): Observable<any> {
    const url = this.baseURL + 'stockActivity/getRawMaterialDetail?licenseNo=' + lic + '&rawMaterialCode=' + raw + '&entityType=BREWERY&id=' + id +'&pageNo='+page +'&pageSize='+ pageSize;
    return this.http.get(url);
  }
  details1(lic, raw, id: Number): Observable<any> {
    const url = this.baseURL + 'stockActivity/getRawMaterialDetail?licenseNo=' + lic + '&rawMaterialCode=' + raw + '&entityType=BREWERY&id=' + id;
    return this.http.get(url);
  }
  detailsPD(lic, raw, id: Number, entityType: any,pageNo,pageSize): Observable<any> {
    const url = this.baseURL + 'stockActivity/getRawMaterialDetail?licenseNo=' + lic + '&rawMaterialCode=' + raw + '&entityType=' + entityType + '&id=' + id +'&pageNo='+pageNo +'&pageSize='+pageSize;
    return this.http.get(url);
  }
  getdetails(category, licanceNo): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licanceNo;
    return this.http.get(url);
  }
  downloadReportVal(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  getSugarMillStockLogs(payload):Observable<any>{
    const url = this.baseURL + 'breweryStockAvailability/getStockDetaillogs';
    return this.http.post(url,payload);
  }
  getSugarMillGroupNameBasedStockDetails(licenseNumber?):Observable<any>{
    const url = this.baseURL + 'getGroupNameWiseStockDetails?licenseNumber=' +licenseNumber;
    //const url = 'http://localhost:3501/scmProduction/getGroupNameWiseStockDetails?licenseNumber=' +licenseNumber;
    return this.http.get(url);
  }
  getStockDetailLog(payload){
    const url = this.baseURL + 'stockLog/getStockDetailsLog'
    return this.http.post(url, payload)
  }
  getLiabilitiesDetails(entityType, licenseNumber): Observable<any>{
    const url = this.baseURL + 'breweryStockAvailability/getSugarMillProductionAndLiabilityDetails?entityType=' 
    + entityType +'&licenseNumber='+licenseNumber;
    return this.http.get(url);
  }

  getDistilleryIndentDistillerySugamillDetais1(entityType):any{
    const url = this.baseURL + 'pdIndentRequest/getPdIndentRequestMollassesAndCaneDetails?entityType='+entityType;
    return this.http.get(url);
  }
}
